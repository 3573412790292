import React from "react";

export default function ProfileImage() {
  return (
    <div className="flex justify-center items-center w-full">
    <img
      className="rounded-lg shadow-md w-full "
     src="/img/cprofile.jpg"
      alt="Resim"
    />
  </div>
  );
}
