import React, { Suspense, lazy, useRef } from "react";
import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

import { Helmet } from "react-helmet";

const DynamicComponentHome = lazy(() =>
  import("../../components/pageBackground")
);

export default function Home() {
  return (
    <>
      <Helmet>
        <title>Demunt</title>
        <meta name="description" content="Home description" />
      </Helmet>
      <Element name="hero-area" className="">
        <Suspense>
          <DynamicComponentHome />
        </Suspense>
      </Element>
    </>
  );
}
