import React from "react";

export default function Adress() {
  return (
    <div className="w-1/2  flex justify-center items-center">
      <img src="/img/manager.png" />
    </div>
    // <div className="w-full md:w-1/3 md:flex  md:mt-0 mt-12">
    //   <div className="ml-3 md:ml-12 ">
    //     <div >
    //       <div className="flex flex-wrap mb-6 items-center">
    //         <div className="contact-icon">
    //           <i className="lni lni-map-marker"></i>
    //         </div>
    //         <p className="pl-3">Solbakkevej 4, <br /> 2630 Taastrup, Denmark</p>
    //       </div>
    //       <div className="flex flex-wrap mb-6 items-center">
    //         <div className="contact-icon">
    //           <i className="lni lni-envelope"></i>
    //         </div>
    //         <p className="pl-3">
    //           <a href="mailto:rdu@demunt.dk" className="block">
    //             info@demunt.dk
    //           </a>
    //         </p>
    //       </div>
    //       <div className="flex flex-wrap mb-6 items-center">
    //         <div className="contact-icon">
    //           <i className="lni lni-phone-set"></i>
    //         </div>
    //         <p className="pl-3">
    //           <a href="tel:+4530258889" className="block">
    //             +45 30 25 88 89
    //           </a>
    //           <a href="tel:+4522453890" className="block">
    //             +45 22 45 38 90
    //           </a>
    //         </p>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}
