import React from "react";
import { Icon } from "../../../icons/Icon";

export default function MenuButton({ openMenu, showMenu,closeMenu }) {
  return (
    <div className="lg:hidden">
      {showMenu ? (
        <span onClick={openMenu} className="cursor-pointer fill-slate-300">
          <Icon name="menuClose" size={35} />
        </span>
      ) : (
        <span onClick={closeMenu} className="cursor-pointer fill-slate-300">
          <Icon name="menu" size={30} />
        </span>
      )}
    </div>
  );
}
