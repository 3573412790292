import React, { useContext, useEffect } from "react";
import { MainContext } from "../../context/MainContext";

export default function About() {
  const { acknowledgement, setAcknowledgement } = useContext(MainContext);

  const showAcknowledgement = () => {
    setAcknowledgement(!acknowledgement);
  };

  return (
    <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 wow fadeInUp">
      <div className="mx-2 mb-8 flex flex-col lg:flex-row lg:space-x-16">
        {/* <h5 className="font-bold text-xl text-white mb-5">Demunt Aps</h5> */}
        <ul className="flex flex-col ">
          <li className="footer-links">Demunt Aps</li>
          <li className="footer-links">info@demunt.dk</li>
          <li className="footer-links">+45 30 25 88 89</li>
          <li className="footer-links">CVR.: 35867104</li>
        </ul>
        <ul>
          <li onClick={showAcknowledgement}>
            <a className="footer-links cursor-pointer">Acknowledgement</a>
          </li>
        </ul>
      </div>
    </div>
  );
}
