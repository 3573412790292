import React, { useContext, useEffect, useState } from "react";
import { MainContext } from "../../context/MainContext";
import client from "../../api";

const Cookies = ({ setCookieModalOpen }) => {
  const [cookies, setCookies ] = useState([]);
  const { locale, setLocale } = useContext(MainContext);

  const closeCookiesModal = () => {
    setCookieModalOpen(false);
  };

  useEffect(() => {
    closeCookiesModal();
    async function cookiesData() {
      try {
        const result = await client.get(
          `/api/cookies?locale=${locale}&populate=*`
        );

        setCookies(result.data.data[0]?.attributes);
      } catch (error) {
        console.log(error);
      }
    }
    cookiesData();
  }, [locale]);
  
  return (
    <div className="w-full bg-black bg-opacity-60 text-white fixed bottom-0 z-50">
      <div className="w-full flex justify-end md:px-10 md:mt-5 px-2 mt-2">
        <button className="bg-white text-black px-3.5 py-1.5 rounded-full text-xl hover:bg-opacity-60" onClick={closeCookiesModal}>X</button>
      </div>
      <div className="w-full flex justify-center items-center">
        <h1 className="md:text-3xl text-lg font-bold pb-6">{cookies.title}</h1>
        {/* <p className="md:text-lg text-white">
          {cookies.desc}
        </p> */}
      </div>
    </div>
  );
};

export default Cookies;
