import React, { useState, useEffect, useContext } from "react";
import "../how-works/works.css";

const WorkSlider = ({ workItem }) => {
  const [position, setPosition] = useState(1);

  const updatePosition = (newPosition) => {
    setPosition(newPosition);
  };

  useEffect(() => {
    const autoRotate = setInterval(() => {
      if (position === 5) {
        setPosition(1);
      } else {
        setPosition(position + 1);
      }
    }, 9000);

    return () => {
      clearInterval(autoRotate);
    };
  }, [position]);

  if (position === 1) {
    const items = document.querySelectorAll(".item");
    items[0]?.classList.add("selected");
  }

  useEffect(() => {
    const items = document.querySelectorAll(".item");
    items.forEach((item, index) => {
      if (index + 1 === position) {
        item.classList.add("selected");
      } else {
        item.classList.remove("selected");
      }
    });
  }, [position]);

  return (
    <>
      <div className="text-center">
        <h2 className="mb-12 pt-5 section-heading md:mt-10 font-Merriweather font-extrabold">
          {workItem[0]?.attributes.label}
        </h2>
      </div>
      <div className="flex flex-wrap justify-center">
        <div id="slider-wrapper">
          <input
            type="radio"
            name="position"
            checked={position === 1}
            onChange={() => updatePosition(1)}
          />
          <input
            type="radio"
            name="position"
            checked={position === 2}
            onChange={() => updatePosition(2)}
          />
          <input
            type="radio"
            name="position"
            checked={position === 3}
            onChange={() => updatePosition(3)}
          />
          <input
            type="radio"
            name="position"
            checked={position === 4}
            onChange={() => updatePosition(4)}
          />
          <input
            type="radio"
            name="position"
            checked={position === 5}
            onChange={() => updatePosition(5)}
          />
          <div id="carousel">
            {workItem.map((d) => {
              return (
                <div key={d.id} className={`item  flex flex-col  px-4`}>
                  <h1 className="font-bold flex justify-center text-xl items-center pt-10">
                    {d.attributes.title}
                  </h1>
                  <h5 className="font-medium pt-5 text-base px-6 lg:px-0"> {d.attributes.desc} </h5>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkSlider;
