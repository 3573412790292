import React, { useContext, useEffect, useState } from "react";
import ProfileDescription from "./ProfileDescription";
import ProfileImage from "./ProfileImage";
import client from "../../api/index";
import { MainContext } from "../../context/MainContext";

export default function CompanyProfile() {
  const [companyItem, setCompanyItem] = useState([]);
  const { locale, setLocale } = useContext(MainContext);

  useEffect(() => {
    async function companyData() {
      try {
        const result = await client.get(
          `/api/demunt-companies?locale=${locale}`
        );

        setCompanyItem(result.data.data);
      } catch (error) {
        console.log(error);
      }
    }
    companyData();
  }, [locale]);

  return (
    <section id="company" className="py-40">
 
      <div className="container mx-auto">
        <div className="md:flex items-center justify-center">
          <ProfileImage companyItem={companyItem} />
          <ProfileDescription companyItem={companyItem} />
        </div>
      </div>
  
    </section>
    // <section id="company" >
    //   <div className="flex flex-wrap  ">
    //     <ProfileImage companyItem={companyItem} />
    //     <ProfileDescription companyItem={companyItem} />
    //   </div>
    // </section>
  );
}
