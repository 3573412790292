import React, { useContext, useEffect, useRef, useState } from "react";
import { MainContext } from "../../context/MainContext";
import { Link } from "react-scroll";
import client from "../../api";

export default function NavBar({
  showMenu,
  closeMenu,
  scrollPosition,
  classNames,
}) {
  const { locale, setLocale } = useContext(MainContext);
  const [item, setItem] = useState([]);
  useEffect(() => {
    async function navData() {
      try {
        const result = await client.get(`/api/demunt-menus?locale=${locale}`);
        setItem(
          result.data.data.sort((a, b) =>
            a.attributes.no > b.attributes.no ? 1 : -1
          )
        );
      } catch (error) {
        console.log(error);
      }
    }
    navData();
  }, [locale]);



  return (
    <div
      className={` ${
        !showMenu ? "hidden" : "block "
      } collapse navbar-collapse lg:block duration-300  shadow-none  absolute top-[100px] left-0 z-20 lg:px-5  py-10 lg:py-3 sm:w-full w-full lg:static bg-black opacity-60 lg:opacity-100 lg:bg-transparent lg:shadow-none`}
      id="navbarSupportedContent"
    >
      <ul
        className={classNames(
          scrollPosition > 100
            ? " text-white flex items-center justify-center lg:flex-row flex-col lg:p-0 p-5 "
            : "text-white  ",
          " navbar-nav mr-auto font-Merriweather z-50 relative font-semibold flex flex-col lg:flex-row justify-center items-center lg:flex md:text-xl text-lg p-5 "
        )}
      >
        {item.map((i) => {
          if (i.attributes.no === 1) {
            return (
              <li
                key={i.attributes.no}
                className="nav-item cursor-pointer  lg:my-5 hover:underline hover:duration-300 z-50"
              ></li>
            );
          } else if (i.attributes.no === 2) {
            return (
              <li className="nav-item cursor-pointer  lg:my-5 mx-3 mb-5 hover:underline hover:duration-300">
                <Link
                  onClick={closeMenu}
                  to="competencies"
                  spy={true}
                  smooth={true}
                  offset={200}
                  duration={500}
                  activeClass="active"
                  className="p-3 rounded-2xl "
                >
                  {i.attributes.name}
                </Link>
              </li>
            );
          } else if (i.attributes.no === 3) {
            return (
              <li className="nav-item cursor-pointer  lg:my-5 mx-3 my-5  hover:underline hover:duration-300">
                <Link
                  onClick={closeMenu}
                  to="collaboration"
                  spy={true}
                  smooth={true}
                  offset={40}
                  duration={500}
                  activeClass="active"
                  className="p-3 rounded-2xl"
                >
                  {i.attributes.name}
                </Link>
              </li>
            );
          }
          // else if (i.attributes.no === 4) {
          //   return (
          //     <li className="nav-item cursor-pointer  lg:my-5 mx-3 my-5  hover:underline hover:duration-300">
          //       <Link
          //         onClick={closeMenu}
          //         to="team"
          //         spy={true}
          //         smooth={true}
          //         offset={10}
          //         duration={500}
          //         activeClass="active"
          //         className="p-3 rounded-2xl"
          //       >
          //         {i.attributes.name}
          //       </Link>
          //     </li>
          //   );
          // }
          else if (i.attributes.no === 5) {
            return (
              <li className="nav-item cursor-pointer  lg:my-5 mx-3 my-5  hover:underline hover:duration-300">
                <Link
                  onClick={closeMenu}
                  to="company"
                  spy={true}
                  smooth={true}
                  offset={200}
                  duration={500}
                  activeClass="active"
                  className="p-3 rounded-2xl"
                >
                  {i.attributes.name}
                </Link>
              </li>
            );
          } else if (i.attributes.no === 6) {
            return (
              <li className="nav-item cursor-pointer lg:my-5 mx-3 mt-5  hover:underline hover:duration-300">
                <Link
                  onClick={closeMenu}
                  to="contact"
                  spy={true}
                  smooth={true}
                  offset={200}
                  duration={500}
                  activeClass="active"
                  className="p-3 rounded-2xl"
                >
                  {i.attributes.name}
                </Link>
              </li>
            );
          }
        })}

        {/* <li className="nav-item cursor-pointer lg:mx-5 lg:my-5  hover:underline hover:duration-300">
          <Link
            onClick={closeMenu}
            to="competencies"
            spy={true}
            smooth={true}
            offset={10}
            duration={500}
            activeClass="active"
            className="p-3 rounded-2xl"
          >
            Competencies
          </Link>
        </li>
        <li className="nav-item cursor-pointer lg:mx-5 lg:my-5  hover:underline hover:duration-300">

          <Link
            onClick={closeMenu}
            to="collaboration"
            spy={true}
            smooth={true}
            offset={10}
            duration={500}
            activeClass="active"
            className="p-3 rounded-2xl"
          >
            Collaboration
          </Link>
        </li>

        <li className="nav-item cursor-pointer lg:mx-5 lg:my-5  hover:underline hover:duration-300">
          <Link
            onClick={closeMenu}
            to="team"
            spy={true}
            smooth={true}
            offset={10}
            duration={500}
            activeClass="active"
            className="p-3 rounded-2xl"
          >
            Team
          </Link>
        </li>
        <li className="nav-item cursor-pointer lg:mx-5 lg:my-5  hover:underline hover:duration-300">
          <Link
            onClick={closeMenu}
            to="company"
            spy={true}
            smooth={true}
            offset={10}
            duration={500}
            activeClass="active"
            className="p-3 rounded-2xl"
          >
            Company
          </Link>
        </li>
        <li className="nav-item cursor-pointer lg:mx-5 lg:my-5  hover:underline hover:duration-300">
          <Link
            onClick={closeMenu}
            to="contact"
            spy={true}
            smooth={true}
            offset={10}
            duration={500}
            activeClass="active"
            className="p-3 rounded-2xl"
          >
            Contact
          </Link>
        </li> */}
      </ul>
    </div>
  );
}
