import React, { useContext, useEffect, useState,useRef  } from "react";
import SideMenu from "./SideMenu";
import { FiMenu } from "react-icons/fi";
import { MainContext } from "../../../context/MainContext";
import { UseScrollPosition } from "../../scroll/UseScrollPosition";
import LanguageIcon from "@mui/icons-material/Language";

const SideBar = () => {
  const { isSideBarOpen, setIsSideBarOpen } = useContext(MainContext);
  const { isTranslationSideBarOpen, setIsTranslationSideBarOpen } = useContext(MainContext);
  const { locale, setLocale } = useContext(MainContext);
  const scrollPosition = UseScrollPosition();
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsTranslationSideBarOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isTranslationSideBarOpen]);

  const handleToggleClick = () => {
    setIsTranslationSideBarOpen(!isTranslationSideBarOpen);
  };

  const handleLangSelect = (lang) => {
    setLocale(lang);
    setIsTranslationSideBarOpen(false);
  };

  const toggleMenu = () => {
    setIsSideBarOpen(!isSideBarOpen);
  };



  return (
    <div className="relative" ref={dropdownRef}>
      {!isSideBarOpen ? (
              <button
              className="fixed top-20 right-0 bg-gray-900 bg-opacity-60 hover:bg-opacity-40 p-4 text-white rounded-xl"
              onClick={toggleMenu}
            >
              <FiMenu size={24} />
            </button>
      ):''}

      <div>
        <SideMenu
          scrollPosition={scrollPosition}
          isOpen={isSideBarOpen}
          onClose={toggleMenu}
        />
      </div>
      {!isSideBarOpen ? (
      <div className="fixed top-36 right-0 mb-5">
      <div className="relative">
        <button
          onClick={handleToggleClick}
          className="p-2 rounded-full font-extrabold hover:border-none active:ring-0
              focus:ring-0 focus:border-none focus:outline-none outline-none text-blue-400"
        >
          <span className="text-2xl">
            <LanguageIcon fontSize="large" fontWeight="bold" />
          </span>
        </button>
        {isTranslationSideBarOpen && (
          <div className="absolute top-12 -right-1 bg-white border rounded shadow-md">
            <ul>
              <li
                className="py-1 cursor-pointer hover:bg-blue-200 w-full px-3"
                onClick={() => handleLangSelect("en")}
              >
                EN
              </li>
              <li
                className="cursor-pointer hover:bg-blue-200 w-full px-3 py-1"
                onClick={() => handleLangSelect("da")}
              >
                DA
              </li>
            </ul>
          </div>
        )}
      </div>
  </div>
      ):''}

    </div>
  );
};

export default SideBar;
