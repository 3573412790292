import React, { useState, useEffect, useContext, useRef } from "react";
import { MainContext } from "../../context/MainContext";
import { UseScrollPosition } from "../scroll/UseScrollPosition";
import Logo from "./Logo";
import MenuButton from "./mobilMenu/MenuButton";
import NavBar from "./NavBar";
import LanguageIcon from '@mui/icons-material/Language';

export default function Header() {
  const { isTranslationOpen, setIsTranslationOpen } = useContext(MainContext);
  const [showMenu, setShowMenu] = useState(false);
  const scrollPosition = UseScrollPosition();
  const { locale, setLocale } = useContext(MainContext);
  const dropRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropRef.current && !dropRef.current.contains(event.target)) {
        setIsTranslationOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isTranslationOpen]);


  const handleToggleClick = () => {
    setIsTranslationOpen(!isTranslationOpen);
  };

  const handleLangSelect = (lang) => {
    setLocale(lang);
    setIsTranslationOpen(false);
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join("");
  }

  const openMenu = () => {
    setShowMenu(!showMenu);
  };
  const closeMenu = () => {
    setShowMenu(!showMenu);
  };

  useEffect(()=>{
    if(scrollPosition > 200){
      setShowMenu(false);
    }
  },[scrollPosition])

  return (
    <header id="header-wrap" className="relative bg-transparent">
      <div
        className={classNames(
          scrollPosition > 100
            ? " "
            : " fixed bg-black bg-opacity-40",
          " navigation  top-0  w-full z-50 duration-300 "
        )}
      >
        <div className="container ">
          <nav className="navbar py-2 navbar-expand-lg flex  relative items-center duration-300 lg:px-6">
            <div className="w-full flex justify-between navbar py-2 navbar-expand-lg items-center relative duration-300 ">
            <div>
              <Logo />
            </div>
            <div>
              <MenuButton
                openMenu={openMenu}
                closeMenu={closeMenu}
                showMenu={showMenu}
              />
              <NavBar
                scrollPosition={scrollPosition}
                classNames={classNames}
                showMenu={showMenu}
                closeMenu={closeMenu}
              />
            </div>
            </div>

            <div className="absolute top-7.5 right-10 lg:right-[0]">
            <div className="relative" ref={dropRef}>
              <button
                onClick={handleToggleClick}
                className=" p-2 rounded-full font-extrabold hover:border-none active:ring-0
                 focus:ring-0 focus:border-none focus:outline-none outline-none text-blue-400"
              >
               <span className="text-2xl"><LanguageIcon fontSize="large" fontWeight="bold"/></span>
              </button>
              {isTranslationOpen && (
                <div className="absolute top-12 -right-1 bg-white border rounded shadow-md">
                  <ul>
                    <li
                      className="py-1 cursor-pointer hover:bg-blue-200 w-full px-3"
                      onClick={() => handleLangSelect("en")}
                    >
                      EN
                    </li>
                    <li
                      className="cursor-pointer hover:bg-blue-200 w-full px-3 py-1"
                      onClick={() => handleLangSelect("da")}
                    >
                      DA
                    </li>
                  </ul>
                </div>
              )}
            </div>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
}
