import React from "react";
import Logo from "../header/Logo";
import About from "./About";
import Company from "./Company";
import FindUsOn from "./FindUsOn";

export default function Footer() {
  return (
    <footer id="footer" className="bg-gray-800 py-28 flex flex-col sm:flex-row">
              <div className="md:ml-20 ml-7 mb-20 md:mb-0">
              <Logo />
            </div>
      <div className="container mx-auto">
        <div className="flex flex-wrap justify-center">
          <About />
          <FindUsOn />
        </div>
      </div>
    </footer>
  );
}
