import React, { useState, useEffect, useContext } from "react";
import WorkSlider from "./WorkSlider";
import client from "../../api";
import { MainContext } from "../../context/MainContext";

const HowWorks = () => {
  const [workItem, setWorkItem] = useState([]);
  const { locale, setLocale } = useContext(MainContext);
  useEffect(() => {
    async function workData() {
      try {
        const result = await client.get(`/api/how-works?locale=${locale}&sort[0]=Order:asc`);
        setWorkItem(result.data.data);
      } catch (error) {
        console.log(error);
      }
    }
    workData();
  }, [locale]);

  return (
    <section className="py-10 text-center">
      <div className="container">
        <WorkSlider workItem={workItem} />
      </div>
    </section>
  );
};

export default HowWorks;
