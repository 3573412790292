import React, { useContext, useEffect, useState } from "react";
import Address from "./Address";
import ContactForm from "./ContactForm";
import Modal from "../../components/modal/Modal"
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import client from "../../api";
import { MainContext } from "../../context/MainContext";


export default function Contact() {
  const { locale, setLocale } = useContext(MainContext);
  const [item, setItem] = useState([]);


  useEffect(() => {
    async function contactData() {
      try {
        const result = await client.get(`/api/demunt-contacts?locale=${locale}`); 

        result.data.data.map((res)=>{
          return setItem(res)
        })

      } catch (error) {
        console.log(error);
      }
    }
    contactData();
  }, [locale]);
  return (
    <Element name="contact" className="py-40 bg-[#e6efff]">
      <div className="container">
        <div className="flex flex-wrap justify-center contact-form-area">
         <Address/>
          <ContactForm item={item} />
          <Modal/>
        </div>
      </div>
    </Element>
  );
}
