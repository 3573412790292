import CollaborationItems from "./CollaborationItems";
import "./collaboration.css";
import client from "../../api";
import { useContext, useEffect, useState } from "react";
import { Icon } from "../../icons/Icon";
import { MainContext } from "../../context/MainContext";

// const colabItem = [
//   {
//     id: 1,
//     icon: <Icon name="solutions" />,
//   },
//   {
//     id: 2,
//     icon: <Icon name="team" />,
//   },
//   {
//     id: 3,
//     icon: <Icon name="profil" />,
//   },
// ];

export default function Collaboration() {
  const [collaborationItems, setCollaborationItems] = useState([]);
  const { locale, setLocale } = useContext(MainContext);
  const { colItem } = useContext(MainContext);

  useEffect(() => {
    async function competenciesData() {
      try {
        const result = await client.get(
          `/api/demunt-collaborations?locale=${locale}`
        );

        setCollaborationItems(result.data.data);
      } catch (error) {
        console.log(error);
      }
    }
    competenciesData();
  }, [locale]);

  return (
    <section id="collaboration" className="py-10 bg-[#e6efff]">
      <div className="container">
        <div className="text-center">
          <h2 className="mb-12 pt-5 section-heading  font-Merriweather">
            {collaborationItems[0]?.attributes.title}
          </h2>
        </div>
        <div className="flex flex-wrap relative z-40">
          {collaborationItems.map((item,i) => {
            return (
              <div key={item.id} className="w-full  md:w-1/2 xl:w-1/3">
                <div class="flex justify-center">
                  <div className="md:mx-10 mx-3">
                    <div className="iconSize">
                      {colItem[i].icon}
                    </div>
                  </div>
                  <div>
                    <h1 className="text-xl xl:text-2xl font-bold text-gray-600 font-nunito w-full ">
                      {item.attributes.name}
                    </h1>
                    <h1 className="pt-5 pb-5 lg:text-base font-semibold sm:text-sm text-black font-nunito">
                      {item.attributes.desc}
                    </h1>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
