import React from "react";
import { Link } from "react-scroll";
import { UseScrollPosition } from "../scroll/UseScrollPosition";

export default function TopLink() {
  const scrollPosition = UseScrollPosition();
  return (
    <div className={`${scrollPosition > 10 ? '':'hidden'}`}>
      <span className="cursor-pointer back-to-top w-10 h-10 fixed bottom-0 right-0 mb-5 mr-5 flex items-center justify-center rounded-full bg-gray-900 bg-opacity-60 text-white text-lg z-40 duration-300 hover:bg-opacity-40">
        <Link
          to="header-wrap"
          spy={true}
          smooth={true}
          offset={50}
          duration={500}
        >
          <i className="lni lni-arrow-up animate-bounce"></i>
        </Link>
      </span>
    </div>
  );
}
