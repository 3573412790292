import { useState } from "react";
import { MainContext } from "../../context/MainContext";
import { useContext } from "react";

export default function ContactForm({ item }) {
  const { modal, setModal } = useContext(MainContext);
  const [isVisitableForm, setIsVisitableForm] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const toggleModal = () => {
    setModal(!modal);
  };
  const toggleDropdownForm = () => {
    setIsVisitableForm(!isVisitableForm);
  };

  const sendMail = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    console.log(JSON.stringify(Object.fromEntries(formData)));

    fetch("/mail", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(Object.fromEntries(formData)),
    })
      .then((response) => response.json())
      .then((data) => {
        toggleModal();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        // Reset form fields
        /*setName("");
        setEmail("");
        setMessage("");
        setSubject("");*/
      });
  };

  return (
    <div className="w-full md:w-1/2 pl-5">
      <div>
        <h2 className="mb-12 lg:text-4xl mt-8 md:mt-0 text-center md:text-left text-3xl text-gray-700 font-bold tracking-wide font-Merriweather">
          {item?.attributes?.name}
        </h2>
      </div>
      <div>
        <h2 className="mb-12 text-lg text-gray-700 font-bold tracking-wide text-center md:text-left font-nunito">
          {item?.attributes?.desc}
        </h2>
      </div>
      <div className=" mb-12">
        <button
          className="bg-blue-500 hover:bg-blue-700 hover:ring-0 hover:border-none ring-0 active:right-0 text-white font-bold py-2 px-4 rounded-2xl"
          onClick={toggleDropdownForm}
        >
          Get In Touch
        </button>
      </div>

      {isVisitableForm && (
        <div
          className={`z-50 relative  animate-fade-up animate-once animate-ease-in mb-64 md:mb-0 ${
            isVisitableForm
              ? "max-h-32 animate-fade-up animate-once animate-ease-in"
              : "max-h-0"
          }`}
        >
          <form
            className="contactForm animate-jump-in animate-once animate-ease-in animate-alternate "
            onSubmit={sendMail}
          >
            <div className="flex flex-wrap">
              <div className="w-full">
                <div className="mx-3 md:mx-0">
                  <input
                    type="text"
                    className="form-input rounded-full"
                    id="name"
                    name="name"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    data-error="Please enter your name"
                  />
                </div>
              </div>
              <div className="w-full">
                <div className="mx-3 md:mx-0">
                  <input
                    type="text"
                    placeholder="e-mail"
                    id="email"
                    className="form-input rounded-full"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    data-error="Please enter your email"
                  />
                </div>
              </div>
              <div className="w-full">
                <div className="mx-3 md:mx-0">
                  <input
                    type="text"
                    placeholder="Subject"
                    id="subject"
                    name="subject"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    className="form-input rounded-full"
                    required
                    data-error="Please enter your subject"
                  />
                </div>
              </div>
              <div className="w-full">
                <div className="mx-3 md:mx-0">
                  <textarea
                    className="form-input rounded-lg"
                    id="message"
                    name="message"
                    placeholder="Your Message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    rows="5"
                    data-error="Write your message"
                    required
                  ></textarea>
                </div>
              </div>
              <div className="w-full flex">
                <div className="mx-3 md:mx-0 w-full">
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-2xl"
                    type="submit"
                  >
                    Send Message
                  </button>
                </div>
                <div className="w-full flex justify-end">
                  <div className="flex flex-col  justify-end">
                    <div className="flex flex-wrap mb-6 items-center">
                      <div className="contact-icon text-sm">
                        <i className="lni lni-envelope"></i>
                      </div>
                      <p className="pl-3">
                        <a
                          href="mailto:rdu@demunt.dk"
                          className="block text-sm"
                        >
                          info@demunt.dk
                        </a>
                      </p>
                    </div>
                    <div className="flex flex-wrap mb-6 items-center text-sm">
                      <div className="contact-icon text-sm ">
                        <i className="lni lni-phone-set"></i>
                      </div>
                      <p className="pl-3 text-sm">
                        <a href="tel:+4530258889" className="block text-sm">
                          +45 30 25 88 89
                        </a>
                        <a href="tel:+4522453890" className="block text-sm">
                          +45 22 45 38 90
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}
