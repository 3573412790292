import React, { useContext, useEffect, useState, lazy, Suspense } from "react";
import "./competencies.css";
import client from "../../api/index";
import { MainContext } from "../../context/MainContext";

const DynamicComponent = lazy(() => import("./CompetenciesItems"));

export default function Services() {
  const [competenciesItem, setCompetenciesItem] = useState([]);
  const { locale, setLocale } = useContext(MainContext);

  useEffect(() => {
    async function competenciesData() {
      try {
        const result = await client.get(
          `/api/demunt-competencies?locale=${locale}&populate=*`
        );

        setCompetenciesItem(result.data.data.sort((a, b) => a.id - b.id));
      } catch (error) {
        console.log(error);
      }
    }
    competenciesData();
  }, [locale]);

  return (
    <>
      <section id="competencies" className="py-40">
        <div className="container mx-auto">
          <div className="text-center">
            <h2 className="mb-12 section-heading  font-Merriweather">
              {competenciesItem[0]?.attributes.title}
            </h2>
          </div>
          <div className="flex justify-center flex-col md:flex-row flex-wrap items-center relative z-40 ">
            {competenciesItem.map((item, index) => {
              return (
                <Suspense>
                  <DynamicComponent item={item} index={index} />
                </Suspense>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}
