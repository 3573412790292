import React from "react";
import { Link } from "react-scroll";

export default function Logo() {
  return (
    <div>
      <Link
        to="hero-area"
        spy={true}
        smooth={true}
        offset={10}
        duration={500}
        className="cursor-pointer"
      >
        <img
        src="img/logo3.png"
          // src="img/logo.png"
          className=" rounded-2xl w-32 md:w-28 lg:w-28 xl:w-40 2xl:w-44 max-w-md "
        />
      </Link>
    </div>
    // <h1 className="text-6xl cursor-pointer font-caveat text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600  hover:from-pink-500 hover:to-yellow-500">
    //   Demunt
    // </h1>
  );
}
