import { createContext, useState } from "react";
import { Icon } from "../icons/Icon";

export const MainContext = createContext();

const Provider = ({ children }) => {
  const [modal, setModal] = useState(false);
  const [acknowledgement, setAcknowledgement] = useState(false);
  const [locale, setLocale] = useState("en");
  const [isTranslationOpen, setIsTranslationOpen] = useState(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const [isTranslationSideBarOpen, setIsTranslationSideBarOpen] =
    useState(false);
  const [colItem, setColItem] = useState([
    {
      id: 1,
      icon: <Icon name="solutions" />,
    },
    {
      id: 2,
      icon: <Icon name="team" />,
    },
    {
      id: 3,
      icon: <Icon name="profil" />,
    },
  ]);

  const data = {
    modal,
    setModal,
    acknowledgement,
    setAcknowledgement,
    locale,
    setLocale,
    colItem,
    isTranslationOpen,
    setIsTranslationOpen,
    isTranslationSideBarOpen,
    setIsTranslationSideBarOpen,
    isSideBarOpen,
    setIsSideBarOpen
  };

  return <MainContext.Provider value={data}>{children}</MainContext.Provider>;
};

export default Provider;
