import React, {
  Suspense,
  lazy,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "../referance/referance.css";
import { MainContext } from "../../context/MainContext";
import client from "../../api";
import Marquee from "react-fast-marquee";

const DynamicComponentReferance = lazy(() => import("./ReferanceItems"));

const Referance = () => {
  const [references, setReferences] = useState([]);
  const { locale, setLocale } = useContext(MainContext);
  const imgItems = references[0]?.attributes?.img?.data

  useEffect(() => {
    async function referencesData() {
      try {
        const result = await client.get(
          `/api/references?locale=${locale}&populate=*`
        );
        setReferences(result.data.data);
      } catch (error) {
        console.log(error);
      }
    }
    referencesData();
  }, [locale]);
  return (
    <section>
      <h2 className="pt-20 section-heading text-center font-Merriweather font-extrabold">
        {references[0]?.attributes.title}
      </h2>
      <div class="sliderr">
        <Suspense>
          <Marquee>
            <DynamicComponentReferance imgItems={imgItems} />
          </Marquee>
        </Suspense>
      </div>
    </section>
  );
};

export default Referance;
