import React, { useEffect, useState } from "react";

const ProfileDescription = ({ companyItem }) => {
  return (
    <div id="company" className="md:w-full  pt-5 md:pt-0 ">
      <div className="max-w-md mx-auto flex flex-col justify-center items-center">
        <h2 className="mb-6 text-left section-heading font-Merriweather">
          {companyItem[0]?.attributes.name}
        </h2>
        <h3 className="text-[#1F2937] text-md xl:text-base font-semibold text-left pl-4 font-nunito">
          {companyItem[0]?.attributes.desc}
        </h3>
      </div>
    </div>
    // <div className="w-full sm:w-full md:w-full lg:w-1/2 mt-5 md:mt-0 md:flex  md:flex-col md:justify-center md:items-center">
    //   <div className="text-center">
    //     <h2 className="mb-12 pt-5 lg:text-5xl text-2xl section-heading font-Merriweather">
    //     {companyItem[0]?.attributes.name}
    //     </h2>
    //   </div>
    //   <div className="text-center md:w-full md:flex md:flex-col md:justify-center md:items-center">
    //     <div className="text-center  md:w-96  ">
    //       <h3 className="text-[#1F2937] text-md lg:text-xl font-normal p-5 ">
    //       {companyItem[0]?.attributes.desc}
    //       </h3>
    //     </div>
    //   </div>
    // </div>
  );
};

export default ProfileDescription;
