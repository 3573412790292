import React, { useContext, useEffect, useState } from "react";
import { MainContext } from "../../context/MainContext";
import client from "../../api";

export default function Acknowledgement() {
  const { acknowledgement, setAcknowledgement } = useContext(MainContext);
  const [acknowData, setAcknowData ] = useState([]);
  const { locale, setLocale } = useContext(MainContext);

  useEffect(() => {
    async function acknowledgementData() {
      try {
        const result = await client.get(
          `/api/acknows?locale=${locale}&populate=*`
        );

        setAcknowData(result.data.data[0]?.attributes);
      } catch (error) {
        console.log(error);
      }
    }
    acknowledgementData();
  }, [locale]);


  const closeAcknowledgement = () => {
    setAcknowledgement(!acknowledgement);
  };

  return (
    <>
      {acknowledgement ? (
        <div className="bg-white w-full transition-transform duration-500 ease-in-out	">
          <div className="w-full flex justify-between py-10 px-5">
            <span></span>
            <span
              onClick={closeAcknowledgement}
              className="w-12 flex justify-center items-center text-2xl text-white cursor-pointer hover:bg-blue-400 bg-blue-600 h-12 rounded-full"
            >
              X
            </span>
          </div>
          <div className="flex flex-col justify-center items-center p-10">
            <h1 className="font-Merriweather font-semibold">
              {acknowData.title}
            </h1>
            <h4 className="py-5 font-Merriweather font-semibold">
            {acknowData.subtitle}
            </h4>
            <h4 className="font-Merriweather font-semibold">
            {acknowData.desc}
            </h4>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
