import React, { useState } from "react";
import "./Modal.css";
import { MainContext } from "../../context/MainContext";
import { useContext } from "react";

export default function Modal() {
  const { modal, setModal } = useContext(MainContext);

  const toggleModal = () => {
    setModal(!modal);
  };

  if (modal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  return (
    <>
      {modal && (
        <div className="modal">
          <div onClick={toggleModal} className="overlay"></div>
          <div className="modal-content">
            <p> Thanks for contacting us, we will get back to you soon.</p>
            {/* <p> Something went wrong, please contact us on telephone or e-mail.</p> */}
            <button className="close-modal font-Merriweather font-semibold" onClick={toggleModal}>
              CLOSE
            </button>
          </div>
        </div>
      )}
    </>
  );
}
