import React, { useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import "./sidebar.css";
import { Link } from "react-scroll";
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import client from "../../../api";
import { MainContext } from "../../../context/MainContext";

const SideMenu = ({ isOpen, onClose }) => {
  // const { locale, setLocale } = useContext(MainContext);
  // const [item, setItem] = useState([]);


  // useEffect(() => {
  //   async function navData() {
  //     try {
  //       const result = await client.get(`/api/demunt-menus?locale=${locale}`);
  //       setItem(
  //         result.data.data.sort((a, b) =>
  //           a.attributes.no > b.attributes.no ? 1 : -1
  //         )
  //       );
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  //   navData();
  // }, [locale]);

  const variants = {
    open: { scale: 1 },
    closed: { scale: 0 },
  };
  return (
    <motion.div
      className={`side-menu ${isOpen ? "open" : ""}`}
      initial={isOpen ? "open" : "closed"}
      animate={isOpen ? "open" : "closed"}
      variants={variants}
    >
      <div className="bg-white w-full h-20 bg-opacity-40">
      <button onClick={onClose} className="absolute top-3 right-3 text-2xl  text-white hover:ring-0 border-none hover:border-none p-2 ">
        <CancelRoundedIcon fontSize="large"/>
      </button>
      </div>
      <ul className="mt-8 flex flex-col justify-center items-end">
        <li className="cursor-pointer  lg:my-5 mx-3 mb-5 hover:underline hover:duration-300">
          <Link
            to="competencies"
            spy={true}
            smooth={true}
            offset={150}
            duration={500}
            
            className="p-3 rounded-2xl bg-transparent text-xl"
          >
            Competencies
          </Link>
        </li>
        <li className="cursor-pointer  lg:my-5 mx-3 my-5  hover:underline hover:duration-300">
          <Link
            to="collaboration"
            spy={true}
            smooth={true}
            offset={-100}
            duration={500}
            className="p-3 rounded-2xl bg-transparent text-xl"
          >
            Collaboration
          </Link>
        </li>
        <li className="nav-item cursor-pointer  lg:my-5 mx-3 my-5  hover:underline hover:duration-300">
          <Link
            to="company"
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
            className="p-3 rounded-2xl bg-transparent text-xl"
          >
            Company
          </Link>
        </li>
        <li className="nav-item cursor-pointer  lg:my-5 mx-3 mt-5  hover:underline hover:duration-300">
          <Link
            to="contact"
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
            className="p-3 rounded-2xl bg-transparent text-xl"
          >
            Contact
          </Link>
        </li>
      </ul>
    </motion.div>
  );
};

export default SideMenu;
