import React, { useState, useRef, useEffect } from "react";
import Collaboration from "../components/collaboration";
import CompanyProfile from "../components/companyprofile";

import TopLink from "../components/topLink";
import Home from "./home";
import Contact from "./contact";
import Competencies from "./competencies";
import HowWorks from "./how-works";
import Referance from "./referance";
import { UseScrollPosition } from "../components/scroll/UseScrollPosition";
import  SideBar  from "../components/header/sidebar";
import Cookies from "../components/Cookies";



export default function Layout() {
  const scrollPosition = UseScrollPosition();

  const [isCookieModalOpen, setCookieModalOpen] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setCookieModalOpen(true);
    }, 3000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <>
      {scrollPosition > 200 ? (
        <div className=" top-96 fixed left-0 z-50">
          <SideBar />
        </div>
      ) : (
        ""
      )}
      <Home />
      <Competencies />
      <HowWorks />
      <Collaboration />
      <CompanyProfile />
      <Contact />
      <TopLink />
      <Referance />
      {isCookieModalOpen && (
        <Cookies setCookieModalOpen={setCookieModalOpen} />
      )}
    </>
  );
}
