import React from 'react'

export default function FindUsOn() {
  return (
    <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 wow fadeInUp">
    <div className="mx-3 mb-8">
      <h3 className="font-bold text-xl text-white mb-5">Find us on</h3>

      <ul className="social-icons flex justify-start">
        <li className="mx-2">
          <a href="https://www.linkedin.com/company/demunt/" className="footer-icon hover:bg-indigo-600">
            <i
              className="lni lni-linkedin-original"
              aria-hidden="true"
            ></i>
          </a>
        </li>
      </ul>
    </div>
  </div>
  )
}
