import Footer from "./components/footer";
import Header from "./components/header";
import Layout from "./pages/Layout";
import Provider from "./context/MainContext";
import Acknowledgement from "./components/acknowledgement";

function App() {
  return (
    <Provider>
      <Header />
      <Layout />
      <Footer />
      <Acknowledgement/>
    </Provider>
  );
}

export default App;